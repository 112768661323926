import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../../../state/store/store';
import AddDebitCard from './add_debit_card/add_debit_card'
import classes from './subscription_form.module.css';


const SubscriptionForm = () => {

    const [ services, setServices ] = useState([])
    const [ debitCards, setDebitCards ] = useState([])
    const [ streamingService, setStreamingService ] = useState("")
    // const [ profileEmail, setProfileEmail] = useState("");
    const [ email, setEmail ] = useState("")
    const [ password, setPassword ] = useState("")
    const [ debitCardNumber, setDebitCardNumber ] = useState("")
    const [ debitCardExpMonth, setDebitCardExpMonth ] = useState("")
    const [ debitCardExpYear, setDebitCardExpYear ] = useState("")
    const [ debitCardCVV, setDebitCardCVV ] = useState("")

    let navigate = useNavigate();

    useEffect(() => {        
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-get_streaming_services')
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setServices(body.streaming_services) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);


    const revealAddDebitCard = () => {
        const x = document.getElementById("add_subscription_form-add_debit_card");
        x.style.height === "0px" ? x.style.height = "100%" : x.style.height = "0px"
    }


    const showPassword = () => {
        const x = document.getElementById("add_subscription_form-subscription_password");
        x.type === "password" ? x.type = "text" : x.type = "password";
    }


    const moveToReviewSubscriptionDetails = ( message ) => {
        const profileEmail = store.getState().islogged.profileEmail
        try {
            console.log(message)
            const addSubscriptionDetails = {
                streamingService, 
                profileEmail,
                email,
                password,
                debitCardNumber,
                debitCardExpMonth,
                debitCardExpYear,
                debitCardCVV
            }
            console.log("TOTAL PAYLOAD:: "+JSON.stringify(addSubscriptionDetails))
            navigate("/add_subscription/add_subscription_review_details", { state: addSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }


    const displaySuccessMessage = (message) => {
        console.log("success message: "+message)
    }


    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 

    
    return ( 
        <div className={classes.add_subscription_form_container}>
            <div className={classes.add_subscription_form} id="add_subscription_form">
                <animated.div style={props}>
                    <h1>Add a subscription</h1>
                    Sign up for an account using the email: , and password: . 
                    <Popup
                        trigger={<Link>Why?</Link>}
                        position={['bottom left', 'bottom right']}
                        closeOnDocumentClick
                        // keepTooltipInside="#add_subscription_form"
                        keepTooltipInside={true}
                        contentStyle={{ width: '20%' }}
                    >
                    <div className={classes.add_subscription_form_info}> 
                        We do not want you to share your personal email and password 
                        with anyone even if you have different passwords for your email 
                        and your current subscription service. 
                        When you're done, return to this page and check the confirmation box.
                    </div>
                    </Popup>
                    <form onSubmit={moveToReviewSubscriptionDetails}>
                    Subscription service:<br /><select onChange={e => setStreamingService(e.target.value)}>
                            <option></option>
                            {services.map((data, index) => (
                                <option key={"services"+index}>
                                    {data}
                                </option>
                            ))}
                        </select><br /><br />
                        {/* <input type="email" onChange={e => setEmail(e.target.value)} id="add_subscription_form-subscription_email" name="subscription_email" placeholder="Your subscription's email" /><br />
                        <input type="password" onChange={e => setPassword(e.target.value)} id="add_subscription_form-subscription_password" name="subscription_password" placeholder="Your subscription's password" /><br /> */}
                        
            {/* <Popup trigger=
                {<button> Click to open popup </button>}
                position="right center">
                <div>GeeksforGeeks</div>
                <button>Click here</button>
            </Popup> */}
                        {/* <input type="checkbox" onChange={showPassword} />I confirm that I have subscribed to {} using the provided email and password.<br /><br /> */}

                        Debit card to receive subscription payment:<br />
                        { debitCards.length > 0 ?
                            <select>
                                <option></option>
                                {debitCards.map((data, index) => (
                                    <option key={"debitCards"+index}>
                                        {data}
                                    </option>
                                ))}
                            </select>
                        :
                            <div id="add_subscription_form-add_debit_card" className={classes.add_subscription_form_add_debit_card}>
                                <AddDebitCard 
                                    setDebitCardNumber={setDebitCardNumber} 
                                    setDebitCardExpMonth={setDebitCardExpMonth} 
                                    setDebitCardExpYear={setDebitCardExpYear} 
                                    setDebitCardCVV={setDebitCardCVV} 
                                />
                            </div>
                        }

                        <input className="add_debit_card" type="button" value="+ Add debit card" onClick={revealAddDebitCard} /><br />
                        
                        <input className="sign_in_submit" type="submit" value="Review details >" />
                    </form>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default SubscriptionForm;
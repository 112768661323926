import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../../../../state/store/store';
import classes from './join_subscription_confirmation_message.module.css';


const JoinSubscriptionConfirmationMessage = () => {

    const subscription = "";
    const email = ""

    let navigate = useNavigate();

    const moveToSubscriptionForm = ( ) => {
        try {
            // const payload = JSON.parse(atob(token.token.split(".")[1]));
            // const user_data = payload.data;
            // store.dispatch(signup_action( token, user_data.id ));
            navigate("/dashboard/subscription_form");
        }catch(error){
            console.log(error);
        }
    }

    return (
        <div className={classes.join_subscription_confirmation_message_container}>
            <div className={classes.join_subscription_confirmation_message}> 
                <h1>You've successfully joined {email}'s {subscription} Subscription</h1>
                <Link to="/dashboard/join_subscription/subscriptions_search">Join another subscription</Link>
            </div>
        </div>
    );
    
}
 
export default JoinSubscriptionConfirmationMessage;
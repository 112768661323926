import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../../../state/store/store';
import VerifySubscription from './verify_subscription/verify_subscription'
import classes from './create_email_account.module.css';


const CreateEmailAccount = () => {

    const [ services, setServices ] = useState([])
    const [ debitCards, setDebitCards ] = useState([])
    const [ streamingService, setStreamingService ] = useState("")
    // const [ profileEmail, setProfileEmail] = useState("");
    const [ subscriptionEmail, setSubscriptionEmail ] = useState("")
    const [ subscriptionEmails, setSubscriptionEmails ] = useState([])

    let navigate = useNavigate();

    useEffect(() => {        
        const profileEmail = store.getState().islogged.profileEmail
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-get_all_add_subscription_emails&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setSubscriptionEmails(body.subscriptionEmails) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);


    const toggleEmailInput = (event) => {
        const x = document.getElementById("add_subscription_form_new_email");
        if(event.target.value === "newSubscriptionEmail"){
            x.style.height = "100%";
            x.style.overflow = "visible";
        }else{
            x.style.height = "0px";
            x.style.overflow = "hidden";
        }
    }


    const showPassword = () => {
        const x = document.getElementById("add_subscription_form-subscription_password");
        x.type === "password" ? x.type = "text" : x.type = "password";
    }


    const moveToCreateSubscription = ( message ) => {
        const profileEmail = store.getState().islogged.profileEmail
        console.log("SUBSCRIPTION EMAIL::");
        console.log(subscriptionEmail);
        // if (subscriptionEmails.length > 0) {
        //     const emailInDom = document.getElementById("subscriptionEmail").value
        //     setSubscriptionEmail(emailInDom)
        // }
        try {
            console.log(message)
            const addSubscriptionDetails = {
                profileEmail,
                subscriptionEmail
            }
            console.log("TOTAL PAYLOAD emailInDom:: "+JSON.stringify(addSubscriptionDetails))
            navigate("/dashboard/add_subscription/create_subscription", { state: addSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }


    const displaySuccessMessage = (message) => {
        console.log("success message: "+message)
    }


    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 

    
    return ( 
        <div className={classes.add_subscription_form_container}>
            <div className={classes.add_subscription_form} id="add_subscription_form">
                <animated.div style={props}>
                    <h1>Create an email account for this subscription</h1>
                    Fill in the email you created. (Please do not add your password here. Never share it with us or anyone on this platform.)
                    <div className={classes.add_subscription_email_account}>
                        <img src="/create_gmail_account_icon.svg" className={classes.add_subscription_email_account_image} alt="" />
                    </div>
                    <Popup
                        trigger={<Link>Why?</Link>}
                        position={['bottom left', 'bottom right']}
                        closeOnDocumentClick
                        // keepTooltipInside="#add_subscription_form"
                        keepTooltipInside={true}
                        contentStyle={{ width: '20%' }}
                    >
                    <div className={classes.add_subscription_form_info}> 
                        We do not want you to share your personal email and password 
                        with anyone even if you have different passwords for your email 
                        and your current subscription service. 
                        When you're done, return to this page and check the confirmation box.
                    </div>
                    </Popup>
                    { subscriptionEmails.length > 0 ? 
                    <div>
                        You've already created an email. Should we use this?<br />
                        <form onSubmit={moveToCreateSubscription}>
                            <div onChange={toggleEmailInput}>
                                {subscriptionEmails.map((data, index) => (
                                    <div>
                                        <input type="radio" id="subscriptionEmail" name="subscriptionEmailRadio" value={data.subscriptionEmail} onClick={() => setSubscriptionEmail(data.subscriptionEmail)} />
                                        <label for="subscriptionEmail">{data.subscriptionEmail}</label><br />
                                    </div>
                                ))}
                                <div>
                                    <input type="radio" id="subscriptionEmail" name="subscriptionEmailRadio" value="newSubscriptionEmail" />
                                    <label for="subscriptionEmail">Use a new email</label><br />
                                </div>
                            </div>
                            <div className={classes.add_subscription_form_new_email} id="add_subscription_form_new_email">
                                <input type="email" className={classes.subscription_email} name="subscription_email" id="subscription_email" placeholder='email' onChange={(e) => setSubscriptionEmail(e.target.value)} />                     
                                <input className="sign_in_submit" type="submit" value="Add subscription email >" />                     
                            </div>
                            <input className="sign_in_submit" type="submit" value="Use this subscription email >" />
                        </form>
                    </div>
                    :
                    <form onSubmit={moveToCreateSubscription}>
                        <input type="email" className={classes.subscription_email} name="subscription_email" id="subscription_email" placeholder='email' onChange={(e) => setSubscriptionEmail(e.target.value)} />                     
                        <input className="sign_in_submit" type="submit" value="Add subscription email >" />
                    </form>
                    }
                </animated.div>               
            </div>
        </div>
    );

}
 
export default CreateEmailAccount;
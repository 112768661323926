import React, { useState, useEffect} from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from './opt_out_of_emails.module.css';

const OptOutOfEmails = () => {
    
    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
        
    const params = useParams();
    const unsubscribeToken = params.unsubscribe_token;
    const email = params.email;
    
    useEffect(() => {
        console.log("API URL: "+process.env.REACT_APP_API_ENDPOINT)
    }, [])

    const optOutOfReceivingEmail = (event) => {
        event.preventDefault();

        try {
            fetch(process.env.REACT_APP_API_ENDPOINT+'resource=opt_out_of_emails', {
                method: 'POST',
                body: JSON.stringify({ 
                    unsubscribeToken,
                    email
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            })
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? console.log(body.userId, body.email) : console.log(false)))
            .catch((error) => {
                console.log("Error message2: "+error);
            });

        }catch(error){
            console.log(error);
        }

    }
    
    return ( 
        <animated.div style={props}>
            <div className={classes.landing_container}>
                <div className={classes.landing}>
                    <div className={classes.landing_two}>
                        <div className={classes.value_proposition}>
                            <h1>Opt out of emails from Shows and Fans</h1>
                            <p>I don't want to recieve any emails from Shows and Fans to {email}.</p>
                            <p>
                                <form onSubmit={optOutOfReceivingEmail}>
                                    {/* <input type="email" placeholder="Enter your email address" /> */}
                                    <button type="submit">Confirm</button>
                                </form>
                            </p>
                        </div>
                            
                    </div>
                </div>
            </div>
        </animated.div>   
    );

}
 
export default OptOutOfEmails;
import React from 'react';
import { useSpring, animated } from 'react-spring';
import classes from './password.module.css';

const PaymentMethod = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                
    return ( 
        <div className={classes.password}>
            <div className={classes.password_item1}>
                <animated.div style={props}>
                    <h1>Payment Method</h1>
                </animated.div> 
            </div>
        </div>
    );

}
 
export default PaymentMethod;
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import classes from './see_how.module.css';

const SeeHow = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                
    return ( 
        <div className={classes.see_how_container}>
            <div className={classes.see_how}>
                <animated.div style={props}>
                    <h1>See How</h1>
                    <Link to="/">Landing</Link>
                    <Link to="/dashboard">Dashboard</Link>
                    <Link to="/policies">Policies</Link>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default SeeHow;
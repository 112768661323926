import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './send_code.module.css';
import store from '../../../../../state/store/store';
import { signup_action } from '../../../../../state/actions';

const SendCode = () => {
    const { state } = useLocation();
    const userId = state.userId;
    const email = state.email
    const [digit1, setValue1] = useState("");
    const [digit2, setValue2] = useState("");
    const [digit3, setValue3] = useState("");
    const [digit4, setValue4] = useState("");
    const [digit5, setValue5] = useState("");
    const [digit6, setValue6] = useState("");
    const [codeResentMessage, setCodeResentMessage] = useState("");

    let navigate = useNavigate();

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    const sendSignUpVerificationCode = () => {
        const verificationCode = digit1+digit2+digit3+digit4+digit5+digit6;
        console.log(verificationCode);
        console.log(userId);
        console.log(email);
         
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=signup-verify_code&verificationCode='+verificationCode+'&userId='+userId+'&email='+email)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? moveToDashboardFromSignIn(body.token) : console.log("You entered a wrong verification code. Try again!")))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }
    

    const reSendSignUpVerificationCode = () => {
        fetch(process.env.REACT_APP_API_ENDPOINT+'/api/signup/resend_verification_code/'+userId)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setCodeResentMessage(body.msg) : codeResentMessage(body.response_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }


    const moveToDashboardFromSignIn = ( token ) => {
        console.log("here: "+token)
        try {
            const payload = JSON.parse(atob(token.token.split(".")[1]));
            const user_data = payload.data;
            store.dispatch(signup_action( token, user_data.user_id, user_data.email ));
            navigate("/dashboard");
        }catch(error){
            console.log(error);
        }
    }


    const nextInput = (e) => {
        const { maxLength, value, name } = e.target;
        const [ fieldName, fieldIndex] = name.split("-");
        const nextSibling = document.querySelector(
            `input[name=digit-${parseInt(fieldIndex, 10) + 1}]`
        );
        
        nextSibling.focus();
    }
                
    const clearInput = (e) => {
        e.target.value = "";
    }

    // style="transition: opacity 0.25s ease 0ms, transform 0.25s ease 0ms;

    return ( 
        <div className={classes.send_code_container}>
            <div className={classes.send_code}>
                <animated.div style={props}>
                    <h1>Send Code</h1>
                    <div className={classes.verification_code}>
                        <input type="text" name="digit-1" id="digit1" onFocus={clearInput} onKeyUp={(e) => {nextInput(e); setValue1(e.target.value);}} maxLength={"1"} autoComplete="off" />
                        <input type="text" name="digit-2" id="digit2" onFocus={clearInput} onKeyUp={(e) => {nextInput(e); setValue2(e.target.value);}} maxLength={"1"} autoComplete="off" />
                        <input type="text" name="digit-3" id="digit3" onFocus={clearInput} onKeyUp={(e) => {nextInput(e); setValue3(e.target.value);}} maxLength={"1"} autoComplete="off" />
                        <input type="text" name="digit-4" id="digit4" onFocus={clearInput} onKeyUp={(e) => {nextInput(e); setValue4(e.target.value);}} maxLength={"1"} autoComplete="off" />
                        <input type="text" name="digit-5" id="digit5" onFocus={clearInput} onKeyUp={(e) => {nextInput(e); setValue5(e.target.value);}} maxLength={"1"} autoComplete="off" />
                        <input type="text" name="digit-6" id="digit6" onFocus={clearInput} onKeyUp={(e) => {setValue6(e.target.value)}} maxLength={"1"} autoComplete="off" />
                    </div>
                    <button type="submit" onClick={sendSignUpVerificationCode}>Send</button>
                    <button type="submit" onClick={reSendSignUpVerificationCode}>Resend Code</button>
                </animated.div>   
                <animated.div style={props}>
                    {codeResentMessage}            
                </animated.div>
            </div>
        </div>
    );

}
 
export default SendCode;
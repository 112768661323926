import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../state/store/store';
import classes from './landing.module.css';

const InDevelopment = () => {
    
    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    return ( 
        <animated.div style={props}>
            <div className={classes.landing_container}>
                <div className={classes.landing}>
                    <div className={classes.landing_one}>
                        <div className={classes.landing_heading}>
                            <h1>Shows and Fans</h1>
                            <h3>Is coming this year, <span className={classes.six_hundred_dollars}>2023</span>. Woooow, we're gonna have fun with this!</h3>
                        </div>
                        
                    </div>

                    <div className={classes.landing_two}>
                        <div className={classes.value_proposition}>
                            <h1>Stay dailed in..</h1>
                            <p>
                                We're bringing what the people want.
                            </p>
                        </div>

                    </div>
                </div>
            </div>
        </animated.div>   
    );

}
 
export default InDevelopment;
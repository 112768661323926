import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../../../state/store/store';
import classes from './published_subscriptions.module.css';

const PublishedSubscriptions = () => {

    const [ publishedSubscriptions, setPublishedSubscriptions ] = useState([])
    const [ joinedSubscriptions, setJoinedSubscriptions ] = useState([])
    // const [ profileEmail, setProfileEmail ] = useState("")

    useEffect(() => {        
        console.log(JSON.stringify(store.getState().islogged.profileEmail))
        const profileEmail = store.getState().islogged.profileEmail
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=subscriptions-get_published_subscriptions&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setPublishedSubscriptions(body.subscriptions) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=subscriptions-get_joined_subscriptions&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setJoinedSubscriptions(body.subscriptions) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    let navigate = useNavigate();

    const redirect = (event) => {
        event.preventDefault();
        navigate("/dashboard/profiles/subscription_profile");
    }
                

    const moveToMessaging = () => {
        // event.preventDefault();
        const profileEmail = store.getState().islogged.profileEmail
        const userId = store.getState().islogged.userId
        const conversationId = "1"
        const data = {
            userId,
            conversationId
        }
        console.log("JOINSUB::");
        console.log(JSON.stringify(data));
        console.log(JSON.stringify(store.getState().islogged));
        navigate("/dashboard/subscriptions/chat", { state: data });
    }

    const enableSharing = () => {
        console.log("Sharing enabled!")
    }
     
        
    return ( 
        <div className={classes.published_subscriptions}>
            <div className={classes.subscriptions_item1}>
                <animated.div style={props}>
                    <h1>Published Subscriptions</h1>

                    <div onClick={moveToMessaging}>message</div>
                    { publishedSubscriptions.length > 0 ?
                    <div>
                        {publishedSubscriptions.map((data, index) => (
                            <div className={classes.subscription} key={"subscription"+index}>
                                {data.streamingService}<br />
                                {data.subscriptionEmail}
                                {data.subscriptionOwnerEmail}<br />
                                <div onClick={enableSharing}>Enable sharing</div>
                            </div>
                        ))}
                    </div>
                    :
                    <div className={classes.publish_first_subscription_link}>
                        None of your subscriptions are being shared. Wanna share one? <Link to="/dashboard/add_subscription/create_email_account">Share here</Link>
                    </div>
                    }
                </animated.div> 
            </div>
        </div>
    );

}
 
export default PublishedSubscriptions;
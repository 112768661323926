import React from 'react';
import classes from './comments.module.css';

const Comments = (props) => {

    return ( 
        <div className={classes.watch_party_comment_container}>
            {props.notification}
            {props.comments.map((text, index) => (
                <div key={index} className={classes.watch_party_comment}>
                    {/* <div key={index+"_name"} className={classes.watch_party_name}>{text}</div> */}
                    {text.userName.userName}<br />
                    {text.comment}
                </div>
            ))}
        </div>
    );

}
 
export default Comments;

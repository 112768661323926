import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from '../state/store/store.js';
import Landing from '../components/canvases/landing/landing';
import InDevelopment from "../components/canvases/landing/in_development.js";
import OptOutOfEmail from '../components/canvases/landing/opt_out_of_emails.js';
import Dashboard from '../components/canvases/dashboard/dashboard';
import Policies from '../components/canvases/policies/policies';
import TermsOfService from '../components/canvases/termsofservice/termsofservice';
import AddSubscription from '../components/canvases/dashboard/add_subscription/add_subscription';
import Charge from '../components/canvases/dashboard/charge/charge';
import Chat from '../components/canvases/dashboard/chat/chat';
import JoinSubscription from '../components/canvases/dashboard/join_subscription/join_subscription';
import Password from '../components/canvases/dashboard/password/password';
import PaymentMethod from '../components/canvases/dashboard/payment_method/payment_method';
import Payout from '../components/canvases/dashboard/payout/payout';
import Reviews from '../components/canvases/dashboard/reviews/reviews';
import Schedule from '../components/canvases/dashboard/schedule/schedule';
import Strikes from '../components/canvases/dashboard/strikes/strikes';
import PublishedSubscriptions from '../components/canvases/dashboard/subscriptions/published_subscriptions';
import JoinedSubscriptions from '../components/canvases/dashboard/subscriptions/joined_subscriptions';
import SignIn from '../components/canvases/onboard/signin/signin';
import ForgotPassword from '../components/canvases/onboard/signin/forgot_password/forgot_password';
import PasswordResetEmailSent from "../components/canvases/onboard/signin/forgot_password/password_reset_email_sent";
import ResetPassword from "../components/canvases/onboard/signin/forgot_password/reset_password";
import SignUp from '../components/canvases/onboard/signup/signup';
import SendCode from '../components/canvases/onboard/signup/send_code/send_code';
import DelayedSendCode from '../components/canvases/onboard/signup/delayed_send_code/delayed_send_code';
import AddPaymentMethod from '../components/canvases/dashboard/payment_method/add_payment_method/add_payment_method';
import RemovePaymentMethod from '../components/canvases/dashboard/payment_method/remove_payment_method/remove_payment_method';
import Comments from '../components/canvases/dashboard/reviews/comments/comments';
import Ratings from '../components/canvases/dashboard/reviews/ratings/ratings';
import TotalRatings from '../components/canvases/dashboard/reviews/total_ratings/total_ratings';
import CurrentStrikes from '../components/canvases/dashboard/strikes/current_strikes/current_strikes';
import SuspendedAccount from '../components/canvases/dashboard/strikes/suspended_account/suspended_account';
import SeeHow from '../components/canvases/landing/info/see_how';
import ProtectedRoutes from './protected_routes.js';
import SubscriptionForm from '../components/canvases/dashboard/add_subscription/subscription_form/subscription_form.js';
import CreateEmailAccount from '../components/canvases/dashboard/add_subscription/subscription_form/create_email_account';
import CreateSubscription from '../components/canvases/dashboard/add_subscription/subscription_form/create_subscription.js';
import AddSubscriptionSetPaymentMethod from "../components/canvases/dashboard/add_subscription/subscription_form/set_payment_method";
import JoinSubscriptionSetPaymentMethod from "../components/canvases/dashboard/join_subscription/subscriptions_search/set_payment_method";
import SubscriberProfile from '../components/canvases/dashboard/profiles/subscriber_profile';
import Footer from "../components/canvases/footer/footer";
import Header from "../components/canvases/header/header";
import AddedSubscriptionReviewDetails from '../components/canvases/dashboard/add_subscription/subscription_form/add_subscription_review_details';
import AddedSubscriptionConfirmationMessage from '../components/canvases/dashboard/add_subscription/subscription_form/added_subscription_confirmation_message'
import SubscriptionsSearch from '../components/canvases/dashboard/join_subscription/subscriptions_search/subscriptions_search.js';
import JoinSubscriptionForm from '../components/canvases/dashboard/join_subscription/subscriptions_search/join_subscription_form.js';
import JoinSubscriptionReviewDetails from '../components/canvases/dashboard/join_subscription/subscriptions_search/join_subscription_review_details'
import JoinSubscriptionConfirmationMessage from '../components/canvases/dashboard/join_subscription/subscriptions_search/join_subscription_confirmation_message';


const routes = (
    <Provider store={store}>
        <Router>

        <Header />
        
            <Routes>
                
                {/* Hello */}
                <Route path='/' element={<Landing />} />
                <Route path='/in_development' element={<InDevelopment />} />
                <Route path='/opt_out_of_emails/:unsubscribe_token' element={<OptOutOfEmail />} />
                <Route path='/auth/sign_in' element={<SignIn />} />
                <Route path='/auth/sign_in/forgot_password' element={<ForgotPassword />} />
                <Route path='/auth/signin/password_reset_email_sent' element={<PasswordResetEmailSent />} />
                <Route path='/auth/signin/reset_password/:email/:token' element={<ResetPassword />} />
                <Route path='/auth/sign_up' element={<SignUp />} />
                <Route path='/auth/sign_up_send_verification_code' element={<SendCode />} />
                <Route path='/auth/delayed_sign_up_send_verification_code' element={<DelayedSendCode />} />
                <Route path='/landing/info/see_how' element={<SeeHow />} />


                {/* Dshboard */}
                <Route element={<ProtectedRoutes />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                    <Route path='/dashboard/add_subscription' element={<AddSubscription />} />
                    <Route path='/dashboard/subscription_form' element={<SubscriptionForm />} />
                    <Route path='/dashboard/add_subscription/create_email_account' element={<CreateEmailAccount />} />
                    <Route path='/dashboard/add_subscription/create_subscription' element={<CreateSubscription />} />
                    <Route path='/dashboard/add_subscription/set_payment_method' element={<AddSubscriptionSetPaymentMethod />} />
                    <Route path='/dashboard/join_subscription/set_payment_method' element={<JoinSubscriptionSetPaymentMethod />} />
                    <Route path='/add_subscription/add_subscription_review_details' element={<AddedSubscriptionReviewDetails />} />
                    <Route path='/add_subscription/add_subscription_confirmation_message' element={<AddedSubscriptionConfirmationMessage />} />
                    <Route path='/dashboard/subscriber_profile' element={<SubscriberProfile />} />
                    <Route path='/dashboard/charge' element={<Charge />} />
                    <Route path='/dashboard/subscriptions/chat' element={<Chat />} />
                    <Route path='/dashboard/join_subscription' element={<JoinSubscription />} />
                    <Route path='/dashboard/join_subscription/subscriptions_search' element={<SubscriptionsSearch />} />
                    <Route path='/dashboard/join_subscription/join_subscription_form' element={<JoinSubscriptionForm />} />
                    <Route path='/dashboard/join_subscription/join_subscription_review_details' element={<JoinSubscriptionReviewDetails />} />
                    <Route path='/dashboard/join_subscription/join_subscription_confirmation_message' element={<JoinSubscriptionConfirmationMessage />} />
                    <Route path='/dashboard/password' element={<Password />} />
                    <Route path='/dashboard/payment_method' element={<PaymentMethod />} />
                    <Route path='/dashboard/payment_method/add_payment_method' element={<AddPaymentMethod />} />
                    <Route path='/dashboard/payment_method/remove_payment_method' element={<RemovePaymentMethod />} />
                    <Route path='/dashboard/payout' element={<Payout />} />
                    <Route path='/dashboard/reviews' element={<Reviews />} />
                    <Route path='/dashboard/reviews/comments' element={<Comments />} />
                    <Route path='/dashboard/reviews/ratings' element={<Ratings />} />
                    <Route path='/dashboard/reviews/total_ratings' element={<TotalRatings />} />
                    <Route path='/dashboard/schedule' element={<Schedule />} />
                    <Route path='/dashboard/strikes' element={<Strikes />} />
                    <Route path='/dashboard/strikes/current_strikes' element={<CurrentStrikes />} />
                    <Route path='/dashboard/strikes/suspended_account' element={<SuspendedAccount />} />
                    <Route path='/dashboard/published_subscriptions' element={<PublishedSubscriptions />} />
                    <Route path='/dashboard/joined_subscriptions' element={<JoinedSubscriptions />} />
                </Route>
                

                {/* Legal */}
                <Route path='/policies' element={<Policies />} />
                <Route path='/termsofservice' element={<TermsOfService />} />

            </Routes>

            <Footer />

        </Router>
    </Provider>
);

export default routes;

import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import classes from './add_subscription.module.css';

const AddSubscription = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                
    return ( 
        <div className={classes.add_subscription_container}>
            <div className={classes.item1}>
                <animated.div style={props}>
                    <div className={classes.add_subscription}>
                        <div className={classes.add_subscription_left}>
                            <h1><Link to="/dashboard/subscription_form">Add Subscription</Link></h1>
                            <div>
                                Subscribe for a streaming service and let's find someone to split the monthly fee with you. 
                                Let's start by signing up for a streaming service.
                            </div>
                        </div>
                        <div className={classes.add_subscription_right}>
                            <Link to="/dashboard/add_subscription/create_email_account"><div className={classes.add_subscription_plus}>+</div></Link>
                        </div>
                    </div>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default AddSubscription;
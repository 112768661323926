import React, { useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../../../../state/store/store';
import classes from './subscriptions_search.module.css';

const SubscriptionsSearch = () => {

    const [ subscriptions, setSubscriptions ] = useState([{"streamingService": "..."}])
    const profileEmail = store.getState().islogged.profileEmail
        
    useEffect(() => {        
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=join_subscription-get_streaming_subscriptions&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setSubscriptions(body.subscriptions) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);


    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    let navigate = useNavigate();

    const goToConfirmSubscription = (subscriptionId, streamingService, subscriptionEmail, subscriptionOwnerEmail) => {
        const joinSubscriptionDetails = {
            subscriptionId,
            streamingService,
            subscriptionEmail,
            subscriptionOwnerEmail,
            profileEmail
        }
        navigate("/dashboard/join_subscription/set_payment_method", { state: joinSubscriptionDetails });
    }
                
    return ( 
        <div className={classes.join_subscription}>
            <div className={classes.subscriptions}>
                <h1>Join a subscription</h1>
                <animated.div style={props}>
                { subscriptions.length > 0 ?
                <div>
                {subscriptions.map((data, index) => (
                    <div className={classes.subscription} key={"subscription"+index} onClick={() => goToConfirmSubscription(data.subscriptionId, data.streamingService, data.subscriptionEmail, data.subscriptionOwnerEmail)}>
                        {data.streamingService}<br />
                        {data.subscriptionEmail}<br />
                        {data.subscriptionOwnerEmail}
                    </div>
                ))}
                </div>
                :
                <div>
                    There aren't any subscriptions to share at the moment. Would you like to create one and split the bill with someone?
                </div>
                }
                </animated.div>               
            </div>
        </div>
    );

}
 
export default SubscriptionsSearch;
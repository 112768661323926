import React, { useEffect, useState, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import { useLocation } from 'react-router-dom';
import store from '../../../../state/store/store';
import classes from './chat.module.css';
import Comments from './comments/comments';


const Chat = () => {
    const { state } = useLocation();
    const userId = state.userId;
    const [timer, setTimer] = useState("")
    const conversationId = state.conversationId;
    const websocket = useRef(null)
    const [comments, setComments] = useState([])
    const [voiceMessages, setVoiceMessages] = useState([])
    const [characters, setCharacters] = useState("")
    const [userName, setUserName] = useState("")
    const [notifications, setNotifications] = useState("")
    const [isPartyStarted, setIsPartyStarted] = useState(false)
    const [watchPartyDetails, setWatchPartyDetails] = useState({})
    const [uiSaySomething, setUiSaySomething] = useState("I want to say something")

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    const countDownToParty = (showTime) => {

        // Set the date we're counting down to
        const countDownDate = new Date("Jan 5, 2024 15:37:25").getTime();

        // Update the count down every 1 second
        const x = setInterval(function() {
            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            const distance = countDownDate - now;

            // Time calculations for days, hours, minutes and seconds
            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            // Display the result in the element with id="demo"
            setTimer( days + "d " + hours + "h " + minutes + "m " + seconds + "s ");

            // If the count down is finished, write some text
            if (distance < 0) {
                clearInterval(x);
                document.getElementById("demo").innerHTML = "EXPIRED";
            }
        }, 1000);
    }



    useEffect( () => {
        console.log("DATA IS::");
        console.log(JSON.stringify(state))
        // if watchparty hasn't started, redirect to countdownpage
        const checkIfWatchPartyStarted = () => {
            const token = store.getState().islogged.fan_token;

            fetch(process.env.REACT_APP_API_ENDPOINT+'/api/watch_parties/check_if_watch_party_started/'+userId+"/"+conversationId, {
                headers: { 
                    'content-type': 'application/json', 
                    'authorization': `Bearer ${token.token}`
                }
            })
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? console.log(body.party_details, body.user_details) : console.log(body.party_details)))
            .catch((error) => {
                console.log("Error message: "+error);
            });
        }

        // (async () => {
        //     await checkIfWatchPartyStarted();
        // })();


        // websocket.current = new WebSocket("wss://3j6hq1emh3.execute-api.us-east-1.amazonaws.com/dev")

        websocket.current = new WebSocket("wss://localhost:3011")

        websocket.current.onopen = (event) => {
                websocket.current.send(JSON.stringify({
                    type: "save_connection_id", 
                    action: "sendmessage", 
                    conversationId: conversationId, 
                    userId: userId
                }));

        }

        let audioNumber = 0;
    
        websocket.current.onmessage = (event) => {
            console.log("SWAP PLATES")
            console.log(event)
            console.log(event.data)
            const parsedComments = JSON.parse(event.data)
            audioNumber++;

            // setComments(comments => [event.data, ...comments])

            if(parsedComments.type === "comment"){
                const comment = parsedComments
                console.log("EXPLAIN CHANGE")
                console.log(JSON.stringify(parsedComments))
                setComments(comments => [comment, ...comments])
            }else if(parsedComments.type === "voice_message"){
                console.log("EXPLAIN VOICE")
                console.log(JSON.stringify(parsedComments))
                const voiceMessage = {
                    audioUrl: parsedComments.audioUrl,
                    audioNumber: audioNumber,
                    userName: parsedComments.userName,
                    played: false
                }
                setVoiceMessages(voiceMessages => [...voiceMessages, voiceMessage])
            }else if(parsedComments.type === "character"){
                // if character exists move em to the front else add
                const message = parsedComments.message;
                const character = {
                    "name": message.name,
                    "effects": [],
                    "user_name": ""
                }
                setCharacters(characters => [character, ...characters])
            }
        }

        websocket.current.onerror = (error) => {
            console.log(`Websocket Error: ${JSON.stringify(error)}`)
        }

        // 

        const send = function (message, callback) {
            waitForConnection(function () {
                websocket.current.send(message);
                if (typeof callback !== 'undefined') {
                  callback();
                }
            }, 1000);
        };
        
        const waitForConnection = function (callback, interval) {
            if (websocket.current.readyState === 1) {
                callback();
            } else {
                var that = this;
                // optional: implement backoff for interval here
                setTimeout(function () {
                    waitForConnection(callback, interval);
                }, interval);
            }
        };
    
        return () => {
            const session = JSON.stringify({
                type: "delete_connection_id", 
                action: "sendmessage", 
                conversationId: conversationId, 
                userId: userId
            });
            send(session, function () {
                console.log("connection deleted successfully")
            })
            websocket.current.close()
        }
    }, []);


    const addComment = (event) => {
        event.preventDefault();
        if(event.key === "Enter"){
            const comment = event.target.value
            event.target.value = ""
            console.log("COMMENT USERNAME:: "+JSON.stringify(userName))
            const commentDetails = {
                comment,
                user_name: {userName}
            }
            websocket.current.send(JSON.stringify({type: "comment", action: "sendmessage", message: commentDetails, user_name: {userName}, conversationId: conversationId, userId: userId}))
        }
    }

    const setUpNotification = (goingOrMaybe) => {
        const token = store.getState().islogged.fan_token;

        fetch(process.env.REACT_APP_API_ENDPOINT+'/api/watch_parties/set_up_notification/', {
            method: 'POST',
            body: JSON.stringify({ 
            userId, 
            conversationId
            }),
            headers: { 
                'content-type': 'application/json', 
                'authorization': `Bearer ${token.token}`
            }
        })
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? console.log("it worked "+JSON.stringify(body)) /*startParty(body.party_details)*/ : console.log("it didn't worked "+JSON.stringify(body)) /*continueCountdown(body.party_details)*/ ))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }


    const confirmPaymentTransfer = () => {
        const token = store.getState().islogged.fan_token;

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=join_subscription-confirm_payment_transfer', {
            method: 'POST',
            body: JSON.stringify({ 
            userId
            }),
            headers: { 
                'content-type': 'application/json', 
                'authorization': `Bearer ${token.token}`
            }
        })
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? console.log("it worked "+JSON.stringify(body)) /*startParty(body.party_details)*/ : console.log("it didn't worked "+JSON.stringify(body)) /*continueCountdown(body.party_details)*/ ))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }


    return (
        <div className={classes.watch_party_container}>
            <div className={classes.watch_party}> 
            <animated.div style={props}>
                <div className={classes.watch_party_details}>
                    <h4><center>{watchPartyDetails.show_title}</center></h4>
                </div>

                <div className={classes.watch_party_talk}>
                    <div className={classes.watch_party_comment_input}>
                        <input type="text" name="" id="" onKeyUp={addComment} placeholder="Comment..." />
                        {notifications}
                    </div>
                    <div className={classes.watch_party_conversations}>
                        <Comments comments={[...comments]} notification={notifications} />
                    </div>
                </div>
                <div className={classes.watch_party_host}>
                    <div className={classes.watch_party_comment_container}>
                        <div className={classes.watch_party_comment}>Host</div>
                        <div className={classes.watch_party_comment}>Text</div>
                    </div>
                </div>

                <div onClick={confirmPaymentTransfer}>Confirm payment transfer</div>
            </animated.div> 
            </div>
        </div>
    );

}
 
export default Chat;
import React from 'react';
import { useNavigate } from 'react-router-dom';
import classes from './subscriber_profile.module.css';


const SubscriberProfile = () => {

    let navigate = useNavigate();

    const leaveSubscription = () => {
        try {
            // TODO
            console.log("I left and a if 2 weeks have passed I don't carry my money on to the next sub. If less than 2 weeks, my money is still credited to me and  I can start all over with a new sub.")
            navigate("/dashboard/join_subscription/join_subscription_confirmation");
        }catch(error){
            console.log(error);
        }
    }

    const sendMessageToSubOwner = () => {
        
    }

    let leave = "";

    let subscriber_id = 21;
    let my_subscriber_id = 21;

    if(subscriber_id !== my_subscriber_id){
        leave = "Leave ";
    }

    return (
        <div className={classes.subscriber_profile}>
            <div className={classes.item1}> 
                <h1>Subscriber's Profile</h1>
                <h1>This is what they are sharing with you.</h1>
                <h1>Or these are you subscriptions.</h1>
                <form onSubmit={leaveSubscription}>
                    <input className="sign_in_submit" type="submit" value="{leave}Subscription 1 (Netflix)" />
                </form>
                <form onSubmit={sendMessageToSubOwner}>
                    <input className="sign_in_submit" type="submit" value="comment on Subscription 1 (Netflix)" />
                </form>
                <form onSubmit={leaveSubscription}>
                    <input className="sign_in_submit" type="submit" value="{leave}Subscription 2 (HBOMax)" />
                </form>
                <form onSubmit={sendMessageToSubOwner}>
                    <input className="sign_in_submit" type="submit" value="comment on Subscription 2 (HBOMax)" />
                </form>
            </div>
        </div>
    );
    
}
 
export default SubscriberProfile;
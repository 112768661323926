import React, { useState }  from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import classes from './forgot_password.module.css';

const ForgotPassword = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                

    const [email, setEmail] = useState("");
    const [signupErrorMsg, setSignupErrorMsg] = useState("");
    
    let navigate = useNavigate();
 
                
    const submitEmail = (event) => {
        event.preventDefault();

        fetch(process.env.REACT_APP_API_ENDPOINT+'/api/signin/forgot_password/'+email)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? navigate("/auth/signin/password_reset_email_sent", { state: email }) : setSignupErrorMsg(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }

    


    return ( 
        <div className={classes.forgot_password_container}>
            <div className={classes.forgot_password}>
                <animated.div style={props}>
                    <h1>Forgot password</h1>
                    <div className={classes.forgot_password_error_message}>{signupErrorMsg}</div>
                    <div>Enter your email here. Click on "Send Code" and check your email for the verification code.</div>
                    <form onSubmit={submitEmail}>
                        <input type="email" onChange={e => setEmail(e.target.value)} id="email" name="email" placeholder="Email" /><br />
                        {/* <input type="checkbox" className="sign_in_checkbox" value="Remember Me!" /> Remember Me!<br /> */}
                        <input className="send_password_reset_link" type="submit" value="Send password reset link" />
                    </form>

                    <div className={classes.forgot_password_alternatives}>
                        <div className={classes.sign_in_forgot_password}>
                            <Link to="/auth/sign_in">Sign in</Link>
                        </div>

                        <div className={classes.sign_up_link}>
                            <Link to="/auth/sign_up">Create an Account</Link>
                        </div>
                    </div>

                    <div className={classes.back_to_dashboard_link}>
                        <Link to="/">{"< Back"}</Link>
                    </div>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default ForgotPassword;
import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
// import FacebookLogin from 'react-facebook-login';
// import AppleLogin from "react-apple-login";
import classes from './signin.module.css';
import store from '../../../../state/store/store';
import { signin_action } from '../../../../state/actions';

const SignIn = () => {
    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    const [formData, setFormData] = useState({
        email: "",
        password: "",
        });
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [signinError, setSigninError] = useState("");
    const [delayedSendCodeLink, setDelayedSendCodeLink] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    
    let navigate = useNavigate();
 
    const handleGoogleSignIn = ( response ) => {
        const credential = response.credential
        console.log(`RESPONSE: ${JSON.stringify(response)}`);
        console.log(`CREDENTIALS: ${credential}`);
        const userGoogleData = jwt_decode(credential)
        const email = userGoogleData.email
        console.log("Encoded response JWT ID: "+JSON.stringify(userGoogleData));

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=google_signin&email='+email+"&googleToken="+encodeURI(credential))
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? moveToDashboardFromSignIn(body.token) : handleErrorMsg(body.sendCodeLink, body.response_msg)))
        .catch((error) => {
            console.log("Error url: "+process.env.REACT_APP_API_ENDPOINT);
            console.log("Error message: "+error);
        });
    }
    
    useEffect(() => {
        navigate("/in_development");
        /* global google */
        window.google.accounts.id.initialize({
            client_id: "580955064802-6o1rs2qqkmauo84r40ge6qv1qhumftiv.apps.googleusercontent.com",
            callback: handleGoogleSignIn
        })
    
        window.google.accounts.id.renderButton(
            document.getElementById("google_signin_div"),
            { theme: "outline", size: "large", type:"standard", shape:"rectangular", text: "continue_with", logo_alignment:"center", width:"500px" }
        )
    }, [])
       
    
    const authenticate = (event) => {
        event.preventDefault();
        setIsLoading(true);

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=signin&email='+email+"&password="+password)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? moveToDashboardFromSignIn(body.token) : handleErrorMsg(body.sendCodeLink, body.response_msg)))
        .catch((error) => {
            console.log("Error url: "+process.env.REACT_APP_API_ENDPOINT);
            console.log("Error message: "+error);
        });
        
    }

    const handleErrorMsg = (sendCodeLink, responseMsg) => {
        setSigninError(responseMsg)
        setDelayedSendCodeLink(sendCodeLink)
        console.log("HERE::::");
        console.log(sendCodeLink);
        setIsLoading(false);
    }

    const moveToDelayedSignUpCodeVerification = () => {
        try {
            console.log("here 1")
            fetch(process.env.REACT_APP_API_ENDPOINT+'resource=signin-delayed_resend_verification_code&email='+email)
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? navigate("/auth/delayed_sign_up_send_verification_code", { state: email }) : setSigninError(body.error_msg)))
            .catch((error) => {
                console.log("Error message: "+error);
            });
        }catch(error){
            setIsLoading(false);
            console.log(error);
        }
    }
    
    const moveToDashboardFromSignIn = ( token ) => {
        try {
            const payload = JSON.parse(atob(token.token.split(".")[1]));
            const userData = payload.data;
            console.log("USER DATA: ");
            console.log(JSON.stringify(userData));
            store.dispatch(signin_action( token, userData.userId, userData.email ));
            navigate("/dashboard");
        }catch(error){
            console.log(error);
        }
    }

    const responseFacebook = (response) => {
        console.log(response);
      }

    const responseApple = (response) => {
        console.log(response);
    }

  
    return ( 
        <div className={classes.signin_container}>
            <div className={classes.signin}>
                <animated.div style={props}>
                    <h1>Sign in</h1>
                                                 
                    <div className={classes.signIn_button_wrapper_container}>
                        <div className={classes.buttonWrapper}>
                            <div id="google_signin_div">
                                <button type="button" onClick={handleGoogleSignIn} className={classes.custom_google_sign_in_button}>
                                    Continue with Google
                                </button>
                            </div>
                        </div>
                        <div className={classes.buttonWrapper}>
                            {/* <FacebookLogin
                                appId="3374051769476211"
                                fields="name,email,public_profile"
                                callback={responseFacebook}
                                autoLoad={false}
                                // callback={handleFacebookSignIn}
                                textButton="Continue with Facebook"
                                // icon="fa-facebook"
                                icon={<i className={`fab fa-facebook-f fa-lg ${classes.facebookIcon}`}></i>}
                                cssClass={classes.facebookButton}
                                render={renderProps => (
                                    <button onClick={renderProps.onClick}>This is my custom FB button</button>
                                )}
                            /> */}
                        </div>
                        <div className={classes.buttonWrapper}>
                            {/* <AppleLogin
                                clientId="com.showsandfans"
                                redirectURI="https://showsandfans.com/auth/sign_in/"
                                responseType="code id_token"
                                // usePopup="true"
                                scope="name email"
                                usePopup={true}
                                callback={responseApple} // Catch the response
                                responseMode="query"
                                // onSuccess={responseApple}
                                // onFailure={responseApple}
                                render={(props) => (
                                <button onClick={props.onClick}  className={classes.appleButton}>
                                    <i className={`fab fa-apple fa-lg ${classes.appleIcon}`}></i> Continue with Apple
                                </button>
                                )}
                            /> */}
                        </div>

                    </div>

                    <br />
                    <center>or</center>
                    <br />
                    <div className={classes.signin_error_message}>
                        {signinError}
                    
                        { delayedSendCodeLink ?
                            <button onClick={moveToDelayedSignUpCodeVerification}>verify email</button> : 
                            ""
                        }
                    </div>
                    <form onSubmit={authenticate}>
                        <input type="email" onChange={e => setEmail(e.target.value)} id="email" name="email" placeholder="Email" />
                        <input type="password" onChange={e => setPassword(e.target.value)} id="password" name="password" placeholder="Password" /><br />
                        {/* <input type="checkbox" className="sign_in_checkbox" value="Remember Me!" /> Remember Me!<br /> */}
                        <button className={classes.sign_in_submit} type="submit" value="Sign in">
                            {isLoading ? (
                                <center><div className={classes.loader}></div></center>
                            ) : (
                                <span>Sign in</span>
                            )}
                        </button>
                        
                    </form>

                    <div className={classes.sign_in_alternatives}>
                        <div className={classes.sign_in_forgot_password}>
                            <Link to="/auth/sign_in/forgot_password">Forgot password?</Link>
                        </div>

                        <div className={classes.sign_up_link}>
                            <Link to="/auth/sign_up">Create an Account</Link>
                        </div>
                    </div>

                    <div className={classes.back_to_dashboard_link}>
                        <Link to="/">{"< Back"}</Link>
                    </div>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default SignIn;
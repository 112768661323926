import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../../../state/store/store';
import VerifySubscription from './verify_subscription/verify_subscription'
import classes from './create_subscription.module.css';


const CreateSubscription = () => {
    // details fro create email account page
    const { state } = useLocation();
    const subscriptionEmail = state.subscriptionEmail
    const profileEmail = state.profileEmail

    // prefilling the create subscription page
    const [ streamingServices, setStreamingServices ] = useState([])

    // filling details from create subscription page
    const [ streamingService, setStreamingService ] = useState("")
    const [ subscriptionPassword, setSubscriptionPassword ] = useState("")

    let navigate = useNavigate();

    useEffect(() => {        
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-get_streaming_services')
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setStreamingServices(body.streaming_services) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);


    const revealAddDebitCard = () => {
        const x = document.getElementById("add_subscription_form-add_debit_card");
        x.style.height === "0px" ? x.style.height = "100%" : x.style.height = "0px"
    }


    const showPassword = () => {
        const x = document.getElementById("add_subscription_form-subscription_password");
        x.type === "password" ? x.type = "text" : x.type = "password";
    }


    const moveToSetPaymentMethod = ( message ) => {
        try {
            console.log(message)
            const addSubscriptionDetails = {
                streamingService, 
                subscriptionEmail,
                subscriptionPassword,
                profileEmail
            }
            console.log("TOTAL PAYLOAD:: "+JSON.stringify(addSubscriptionDetails))
            navigate("/dashboard/add_subscription/set_payment_method", { state: addSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }

    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 


    const enableSubscrionSharing = () => {
        const token = store.getState().islogged.fan_token;

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-enable_subscription_sharing', {
            method: 'POST',
            body: JSON.stringify({ 
                profileEmail
            }),
            headers: { 
                'content-type': 'application/json', 
                'authorization': `Bearer ${token.token}`
            }
        })
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? console.log("it worked "+JSON.stringify(body)) /*startParty(body.party_details)*/ : console.log("it didn't worked "+JSON.stringify(body)) /*continueCountdown(body.party_details)*/ ))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }

    
    return ( 
        <div className={classes.add_subscription_form_container}>
            <div className={classes.add_subscription_form} id="add_subscription_form">
                <animated.div style={props}>
                    <h1>Create a subscription</h1>
                    Sign up for your streaming service with the email: . Use this password, . 
                    (Please do not use your email account password to sign-up for this streaming service. We want you to be the only one to who has the ability to change your streaming service's password through your new email account.) <br /><br />
                    <Popup
                        trigger={<Link>Why?</Link>}
                        position={['bottom left', 'bottom right']}
                        closeOnDocumentClick
                        // keepTooltipInside="#add_subscription_form"
                        keepTooltipInside={true}
                        contentStyle={{ width: '20%' }}
                    >
                    <div className={classes.add_subscription_form_info}> 
                        We do not want you to share your personal email and password 
                        with anyone even if you have different passwords for your email 
                        and your current subscription service. 
                        When you're done, return to this page and check the confirmation box.
                    </div>
                    </Popup>
                    <form onSubmit={moveToSetPaymentMethod}>
                    What subscription service did you sign-up for?
                    <br /><select onChange={e => setStreamingService(e.target.value)}>
                            <option></option>
                            {streamingServices.map((data, index) => (
                                <option key={"services"+index}>
                                    {data}
                                </option>
                            ))}
                        </select><br />
                        Email: <b>{subscriptionEmail}</b>
                        <br />
                        Password: <input type="password" className={classes.subscriptionPassword} name="subscriptionPassword" id="subscriptionPassword" placeholder='password' onChange={(e) => setSubscriptionPassword(e.target.value)} /> <br />
                        <div onClick={enableSubscrionSharing}>Enable sharing</div>
                        <input className="sign_in_submit" type="submit" value="Add payment receiving debit card >" />
                    </form>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default CreateSubscription;
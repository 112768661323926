import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../../../../state/store/store';
import { signup_action } from '../../../../../state/actions';
import classes from './added_subscription_confirmation_message.module.css';


const AddedSubscriptionConfirmationMessage = () => {

    let subscription = "";

    let navigate = useNavigate();

    const moveToSubscriptionForm = ( ) => {
        try {
            // const payload = JSON.parse(atob(token.token.split(".")[1]));
            // const user_data = payload.data;
            // store.dispatch(signup_action( token, user_data.id ));
            navigate("/dashboard/subscription_form");
        }catch(error){
            console.log(error);
        }
    }

    return (
        <div className={classes.add_subscription_confirmation_message_container}>
            <div className={classes.add_subscription_confirmation_message}> 
                <h1>You've successfully added your {subscription} Subscription</h1>
                <Link to="/dashboard/subscription_form">Add another subscription</Link>
            </div>
        </div>
    );
    
}
 
export default AddedSubscriptionConfirmationMessage;
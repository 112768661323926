import React, { useState, useEffect } from 'react';
import classes from './add_debit_card.modules.css';


const AddDebitCard = () => {

    const [ time, setTime ] = useState([])
    const [ date, setDate ] = useState([])
    
    const addDebitCard = () => {

    }

    return ( 
        <div className={classes.policies}>
            <form onSubmit={addDebitCard}>
                <input type="text" id="add_subscription_form-add_debit_card-card_number" name="card_number" placeholder="Debit card number" /><br />
                <input className={classes.date} type="date" name="date" id="date" onChange={(e) => setDate(e.target.value)} placeholder="Exp. date" /><br />
                <input type="number" id="add_subscription_form-add_debit_card-CVV" name="CVV" placeholder="CVV" /><br />       
            </form>
        </div>
    );
}

export default AddDebitCard;
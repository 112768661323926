import React from 'react';
import { useSpring, animated } from 'react-spring';
// import { Link } from 'react-router-dom';
import classes from './charge.module.css';

const Charge = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                
    return ( 
        <div className={classes.policies}>
            <div className={classes.item1}>
                <animated.div style={props}>
                    <h1>Charge</h1>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default Charge;
import React  from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useLocation } from 'react-router-dom';
import classes from './password_reset_email_sent.module.css';

const ForgotPassword = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
    const { state } = useLocation();
    const email = state;


    return ( 
        <div className={classes.signin_container}>
            <div className={classes.signin}>
                <animated.div style={props}>
                    <h1>Email was successfully sent</h1>
                    <div>A link to reset your password has been sent to your email, {email}.</div>

                    <div className={classes.sign_in_alternatives}>
                        <div className={classes.sign_in_forgot_password}>
                            <Link to="/auth/sign_in">Sign in</Link>
                        </div>

                        <div className={classes.sign_up_link}>
                            <Link to="/auth/sign_up">Create an Account</Link>
                        </div>
                    </div>

                    <div className={classes.back_to_dashboard_link}>
                        <Link to="/">{"< Back"}</Link>
                    </div>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default ForgotPassword;
import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link } from 'react-router-dom';
import store from '../../../state/store/store';
import classes from './header.module.css';
import { signout_action } from '../../../state/actions';

const Header = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    const [isAuth, setIsAuth] = useState(store.getState().islogged.authenticated)

    store.subscribe(() => {
        setIsAuth(store.getState().islogged.authenticated)
    })

    const signOut = () => {
        store.dispatch(signout_action());
    }
                
    return ( 
        <div className={classes.header_container}>
            <div className={classes.header}>
                {/* {isAuth ?
                    <animated.div style={props}>   
                        <Link to="/">Landing</Link>
                        <Link to="/dashboard">Lobby</Link>
                        <Link to="/policies">Settings</Link>
                        <input type="button" onClick={signOut} value="Sign out" />
                    </animated.div>   
                :
                    <animated.div style={props}>   
                        <Link to="/">Landing</Link>
                        <Link to="/dashboard">Lobby</Link>
                        <Link to="/auth/sign_in">Sign in</Link>
                        <Link to="/auth/sign_up">Sign up</Link>
                    </animated.div> 
                }       */}
            </div>
        </div>
    );

}
 
export default Header;
import routes from './routes/routes';
import '@fortawesome/fontawesome-free/css/all.css';
import './App.css';


function App() {
  return (
    <div className="App">

      { routes }

    </div>
  );
}

export default App;

import React, { useState, useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../state/store/store';
import classes from './landing.module.css';

const Landing = () => {
    
    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
                
    let navigate = useNavigate();

    useEffect(() => {
        navigate("/in_development");
    }, [])
    
    const [steps, setSteps] = useState([
        {
          title: 'Create an Email Account',
          description: 'You can reuse this email account if you decide to subscribe to more streaming services.',
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a3/Email_icon.svg/1200px-Email_icon.svg.png',
        },
        {
          title: 'Choose the Streaming Service You Want to Subscribe to',
          description: 'We offer a variety of streaming services to choose from, including Netflix, Hulu, and Disney+.',
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Netflix_Logo.svg/1200px-Netflix_Logo.svg.png',
        },
        {
          title: 'Add Your Debit Card to Receive Payments from Anyone Who Will Share Your Subscription',
          description: 'You can reuse this debit card to receive payments from anyone who will share your subscription.',
          icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/a/a4/Debit_card_icon.svg/1200px-Debit_card_icon.svg.png',
        },
      ]);
      
      
    return ( 
        <animated.div style={props}>
            <div className={classes.landing_container}>
                <div className={classes.landing}>
                    <div className={classes.landing_one}>
                        <div className={classes.landing_heading}>
                            <h1>Shows and Fans</h1>
                            <h3>Streaming services are expensive so share your subscription and split the bill with others right here. You could save on average <span className={classes.six_hundred_dollars}>$600</span> every year and continue to enjoy the shows you love.</h3>
                        </div>
                        { store.getState().islogged.authenticated ?
                        <div className={classes.landing_signin_signup}>
                            <div className={classes.landing_signup}>
                                <Link to="/dashboard">Your Space</Link>
                            </div>
                        </div>
                        : 
                        <div className={classes.landing_signin_signup}>
                            <div className={classes.landing_signin}>
                                <Link to="/auth/sign_in">Sign in</Link>
                            </div>
                            <div className={classes.landing_signup}>
                                <Link to="/auth/sign_up">I'm new here.</Link>
                            </div>
                        </div>
                        }
                    </div>

                    <div className={classes.landing_two}>
                        <div className={classes.value_proposition}>
                            <h1>You could save $600 per year on streaming subscription fees!</h1>
                            <p>
                                Did you know that the average person spends over $100 a month on streaming services? That's over $1,200 a year! 
                                But with Shows and Fans, you can save up to half of your subscription bills without sacrificing your favorite shows and movies. 
                                That's $600 extra you can spend on whatever you want.
                            </p>
                            <form>
                                {/* <input type="email" placeholder="Enter your email address" /> */}
                                <button type="submit">Start Saving Now</button>
                            </form>
                        </div>
                            
                        <div className={classes.value_proposition}>
                            <h1>How to share subscriptions on Shows and Fans</h1>
                            <p>
                                You have the option to create new subscription accounts and share them with other people on Shows and Fans. 
                                Or if you prefer to join subscription owners, you can do that too. <br /><br />Every month, after the trial period of the subscription service, 
                                we will charge and transfer half the subscription fee from the fan joining the subscription to the subscription owner. So you split the fee instead of paying full price. Genius, right!?
                            </p>
                            <form>
                                {/* <input type="email" placeholder="Enter your email address" /> */}
                                <button type="submit">Start Saving Now</button>
                            </form>
                        </div>

                        <div className={classes.value_proposition}>
                        <h1>Welcome to the subscription sharing</h1>
                            <h2>If you want to own and share a subscription, do this</h2>
                            <p>When you're a subscription owner on Shows and Fans, 
                                you create and use a sstreaming service subscription and allow others 
                                to share your subscrition the subscription fee. 
                                If you'd like to be a subscription owner, here are the steps.
                            </p>


                            <div className={classes.landing_page}>
                            <div className={classes.steps_container}>
                                <div className={classes.step}>
                                <h3>Step 3: Create an Email Account</h3>
                                <p>You can reuse this email to sign up for more than one streaming service. <span>Why do you need a fresh email account?</span> For the best security, we don't want you to share your personal email. Let's start afresh.</p>
                                </div>
                                <div className={classes.step}>
                                <h3>Step 2: Create a Subscription</h3>
                                <p>Subscribe to your favorite streaming services. Remember you can reuse your new email account created in Step 1.</p>
                                </div>
                                <div className={classes.step}>
                                <h3>Step 3: Add Your Debit Card, that's it!</h3>
                                <p>Add your receiving debit card. Receive payments from anyone who will share your subscriptions using this debit card.</p>
                                </div>
                            </div>
                            </div>


                            <h2>If you want to share a subscription owned by someone else, do this</h2>
                            <p>When you're a subscription owner on Shows and Fans, 
                                you create and use a sstreaming service subscription and allow others 
                                to share your subscrition the subscription fee. If you'd like to be a subscription owner, here are the steps.</p>
                            
                                <div className={classes.landing_page}>
                            <div className={classes.steps_container}>
                                <div className={classes.step}>
                                <h3>Search for a subscription</h3>
                                <p>You can reuse this if you decide to subscribe to more streaming services.</p>
                                </div>
                                <div className={classes.step}>
                                <h3>Add your debit/credit card</h3>
                                <p>Subscribe to your favorite streaming services.</p>
                                </div>
                                <div className={classes.step}>
                                <h3>Confirm sharing</h3>
                                <p>You will see the email and password in your dashboard.</p>
                                </div>
                            </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </animated.div>   
    );

}
 
export default Landing;
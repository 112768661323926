import React from 'react';
import { useSpring, animated } from 'react-spring';
import Comments from './comments/comments';
import Ratings from './ratings/ratings';
import TotalRatings from './total_ratings/total_ratings';
import classes from './reviews.module.css';

const Reviews = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                
    return ( 
        <div className={classes.policies}>
            <div className={classes.item1}>
                <animated.div style={props}>
                    <h1>Reviews</h1>
                </animated.div>  
                <div>
                    <Comments />
                    <Ratings />
                    <TotalRatings />
                </div>                       
            </div>
        </div>
    );

}
 
export default Reviews;
import { useState } from 'react';
import { Navigate, Outlet } from 'react-router';
import store from '../state/store/store';

const ProtectedRoutes = () => {
    
    const [authenticated, isAuthenticated] = useState(store.getState().islogged.authenticated)
   
    store.subscribe(() => {
        isAuthenticated(store.getState().islogged.authenticated)
        console.log(JSON.stringify(store.getState().islogged.authenticated))
    })
 
    return authenticated ? <Outlet /> : <Navigate to="/auth/sign_in" />
}

export default ProtectedRoutes;
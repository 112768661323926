import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
import store from '../../../../../state/store/store';
import AddDebitCard from './add_debit_card/add_debit_card'
import classes from './join_subscription_form.module.css';


const JoinSubscriptionForm = () => {
    const { state } = useLocation();
    const subscriptionDetails = state;

    const [ cards, setDebitCards ] = useState([])
    const [ streamingService, setStreamingService ] = useState("")
    const [ profileEmail, setProfileEmail] = useState("");
    const [ email, setSubscriptionEmail ] = useState("")
    const [ cardDetails, setCardDetails ] = useState({})

    let navigate = useNavigate();

    // useEffect(() => {        
    //     // fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-get_streaming_services')
    //     // .then(res => res.json())
    //     // .then((body) => (body.response_msg === "success" ? setServices(body.streaming_services) : console.log(body.error_msg)))
    //     // .catch((error) => {
    //     //     console.log("Error message: "+error);
    //     // });
    // }, []);


    const revealAddDebitCard = () => {
        const x = document.getElementById("join_subscription_form-add_debit_card");
        x.style.height === "0px" ? x.style.height = "100%" : x.style.height = "0px"
    }


    const moveToReviewJoinSubscriptionDetails = (event) => {
        event.preventDefault();
        setProfileEmail(store.getState().islogged.profileEmail)
        setSubscriptionEmail(subscriptionDetails.subscriptionOwnerEmail)
        setStreamingService(subscriptionDetails.streamingService)
        try {
            const joinSubscriptionDetails = {
                "streamingService": subscriptionDetails.streamingService, 
                "profileEmail": store.getState().islogged.profileEmail,
                "subscriptionOwnerEmail": subscriptionDetails.subscriptionOwnerEmail,
                "cardDetails": {}
            }
            console.log("VALUE: "+JSON.stringify(joinSubscriptionDetails))
            navigate("/dashboard/join_subscription/join_subscription_review_details", { state: joinSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }


    const displaySuccessMessage = (message) => {
        console.log("success message: "+message)
    }


    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 

    return ( 
        <div className={classes.join_subscription_form_container}>
            <div className={classes.join_subscription_form}>
                <animated.div style={props}>
                    <h1>Join a subscription</h1>
                    <form onSubmit={moveToReviewJoinSubscriptionDetails}>
                    Subscription service:<br />
                        {subscriptionDetails.streamingService}
                    Owner Email:<br />
                        {subscriptionDetails.subscriptionOwnerEmail}<br />
                    Debit card to send your share of the subscription payment:<br />
                    { cards.length > 0 ?
                        <select>
                            <option></option>
                            {cards.map((data, index) => (
                                <option key={"cards"+index}>
                                    {data}
                                </option>
                            ))}
                        </select>
                    :
                        <div id="join_subscription_form-add_debit_card" className={classes.join_subscription_form_add_debit_card}>
                            <AddDebitCard />
                        </div>
                    }
                        

                        <input className="add_debit_card" type="button" value="+ Add debit card" onClick={revealAddDebitCard} /><br />
                        
                        <input className="sign_in_submit" type="submit" value="Review details >" />
                    </form>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default JoinSubscriptionForm;
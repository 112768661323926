export const signup_action = ( fan_token, id, email ) => {
  return {
    type: "SIGN_UP",
    fan_token,
    id,
    email
  }
}

export const signin_action = ( fan_token, id, email ) => {
  return {
    type: "SIGN_IN",
    fan_token,
    id,
    email
  }
}

export const signout_action = () => {
  return {...{
    type: "SIGN_OUT"
  }}
}
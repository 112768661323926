import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import store from '../../../../../state/store/store';
import { signup_action } from '../../../../../state/actions';
import classes from './join_subscription_review_details.module.css';


const JoinSubscriptionReviewDetails = () => {
    const { state } = useLocation();
    const joinSubscriptionDetails = state;
    const subscriptionId = joinSubscriptionDetails.subscriptionId
    const subscriptionEmail = joinSubscriptionDetails.subscriptionEmail;
    const subscriptionOwnerEmail = joinSubscriptionDetails.subscriptionOwnerEmail;
    const profileEmail = joinSubscriptionDetails.profileEmail
    const streamingService = joinSubscriptionDetails.streamingService;
    const cardDetails = joinSubscriptionDetails.cardDetails
    const isNewCard = joinSubscriptionDetails.isNewCard

    let subscription = "";

    let navigate = useNavigate();


    const joinSubscription = (event) => {
        event.preventDefault();
        try {
            fetch(process.env.REACT_APP_API_ENDPOINT+'resource=join_subscription-join_subscription_details', {
                method: 'POST',
                body: JSON.stringify({ 
                    subscriptionId,
                    subscriptionEmail,
                    subscriptionOwnerEmail,
                    profileEmail,
                    streamingService,
                    cardDetails,
                    isNewCard
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            })
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? moveToJoinSubscriptionConfirmation(body.message) : console.log(JSON.stringify(body))))
            .catch((error) => {
                console.log("Error message2: "+error);
            });

        }catch(error){
            console.log(error);
        }

    }

    const moveBackToJoinSubscriptionForm = () => {
        try {
            navigate("/dashboard/join_subscription/join_subscription_form", { state: joinSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }

    const moveToJoinSubscriptionConfirmation = ( message ) => {
        try {
            navigate("/dashboard/join_subscription/join_subscription_confirmation_message");
        }catch(error){
            console.log(error);
        }
    }

    return (
        <div className={classes.join_subscription_review_container}>
            <div className={classes.join_subscription_review}> 
                <h1>Review the details {subscription} Subscription</h1>
                <div>
                    <div>Streaming service: {streamingService}</div>
                    <div>The subscription email: {subscriptionEmail}</div>
                    {/* <div>Your subscription password: {password}</div>
                    <div> card to receive payment: {debitCardDetails.card}</div> */}
                </div>
                <div>
                    <form onSubmit={moveBackToJoinSubscriptionForm}>
                        <input className="sign_in_submit" type="submit" value="I changed my mind, let's go back." />
                    </form>

                    <form onSubmit={joinSubscription}>
                        <input className="sign_in_submit" type="submit" value="Join this subscription now." />
                    </form>
                </div>
                {/* <Link to="/dashboard">Dashboard</Link> */}
            </div>
        </div>
    );
    
}
 
export default JoinSubscriptionReviewDetails;
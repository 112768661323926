import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import classes from './join_subscription.module.css';

const JoinSubscription = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    let navigate = useNavigate();
    
    const redirect = (event) => {
        event.preventDefault();
        navigate("/dashboard/join_subscription/join_subscription_confirmation");
    }
                
    return ( 
        <div className={classes.join_subscription_container}>
            <div className={classes.item1}>
                <animated.div style={props}>
                    <div className={classes.join_subscription}>
                        <div className={classes.join_subscription_left}>
                            <h1><Link to="/dashboard/join_subscription/subscriptions_search">Join Subscription</Link></h1>
                            <div>
                                Split the bill on a streaming service subscription so you pay less. 
                                Let's find someone who's looking to share their subscription with you.
                            </div>
                        </div>
                        <div className={classes.join_subscription_right}>
                            <Link to="/dashboard/join_subscription/subscriptions_search"><div className={classes.join_subscription_plus}>{"><"}</div></Link>
                        </div>
                    </div>
                </animated.div>               
            </div>
        </div>
    );

}
 
export default JoinSubscription;
import React, { useState, useEffect } from 'react';
import classes from './add_debit_card.modules.css';


const AddDebitCard = (props) => {

    const [ time, setTime ] = useState([])
    const [ date, setDate ] = useState([])
    
    const addDebitCardNumber = (e) => {
        const debitCardNumber = e.target.value
        props.setDebitCardNumber(debitCardNumber)
    }

    const addDebitCardExpMonth = (e) => {
        const debitCardExpMonth = e.target.value
        props.setDebitCardExpMonth(debitCardExpMonth)
    }

    const addDebitCardExpYear = (e) => {
        const debitCardExpYear = e.target.value
        props.setDebitCardExpYear(debitCardExpYear)
    }

    const addDebitCardCVV = (e) => {
        const debitCardExpCVV = e.target.value
        props.setDebitCardExpCVV(debitCardExpCVV)
    }

    return ( 
        <div className={classes.policies}>
            <form>
                <input type="text" id="add_subscription_form-card_number" name="card_number" onChange={(e) => addDebitCardNumber(e.target.value)}  placeholder="Debit card number" /><br />
                <input className={classes.add_subscription_form_exp_month} type="text" name="add_subscription_form-exp_month" id="add_subscription_form-exp_month" onChange={(e) => addDebitCardExpMonth(e.target.value)} placeholder="MM" /><br />
                <input className={classes.add_subscription_form_exp_year} type="text" name="add_subscription_form-exp_year" id="add_subscription_form-exp_year" onChange={(e) => addDebitCardExpYear(e.target.value)} placeholder="YY" /><br />
                <input type="text" id="add_subscription_form-CVV" name="add_subscription_form-CVV" onChange={(e) => addDebitCardCVV(e.target.value)} placeholder="CVV" /><br />    
            </form>
        </div>
    );
}

export default AddDebitCard;
import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import store from '../../../../../state/store/store';
import { signup_action } from '../../../../../state/actions';
import classes from './add_subscription_review_details.module.css';


const AddedSubscriptionReviewDetails = () => {
    const { state } = useLocation();
    const addSubscriptionDetails = state;
    const profileEmail = addSubscriptionDetails.profileEmail;
    const streamingService = addSubscriptionDetails.streamingService;
    const subscriptionEmail = addSubscriptionDetails.subscriptionEmail;
    const subscriptionPassword = addSubscriptionDetails.subscriptionPassword;
    const debitCardDetails = addSubscriptionDetails.debitCardDetails;

    let subscription = "";

    let navigate = useNavigate();

    const addSubscription = (event) => {
        console.log("DEBIT CARD DETAILS::");
        console.log(JSON.stringify(debitCardDetails));
        console.log(subscriptionEmail);
        event.preventDefault();
        try {
            fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-add_subscription_details', {
                method: 'POST',
                body: JSON.stringify({ 
                    subscriptionEmail,
                    subscriptionPassword,
                    streamingService, 
                    profileEmail,
                    debitCardDetails
                }),
                headers: { 
                    'Content-Type': 'application/json',
                    'Accept': '*/*'
                }
            })
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? moveToAddedSubscriptionConfirmation(body.message) : console.log(JSON.stringify(body))))
            .catch((error) => {
                console.log("Error message2: "+error);
            });

        }catch(error){
            console.log(error);
        }

    }

    const moveToSubscriptionForm = ( ) => {
        try {
            navigate("/dashboard/add_subscription/create_email_account", { state: addSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }

    const moveToAddedSubscriptionConfirmation = ( message ) => {
        try {
            // const payload = JSON.parse(atob(token.token.split(".")[1]));
            // const user_data = payload.data;
            // store.dispatch(signup_action( token, user_data.id ));
            navigate("/add_subscription/add_subscription_confirmation_message");
        }catch(error){
            console.log(error);
        }
    }

    return (
        <div className={classes.add_subscription_review_container}>
            <div className={classes.add_subscription_review}> 
                <h1>You've successfully added your {subscription} Subscription</h1>
                <div>
                    <div>Streaming service: {streamingService}</div>
                    <div>Your email account: {profileEmail}</div>
                    <div>Your subscription email: {subscriptionEmail}</div>
                    <div>Your subscription password (not the same as my email's password &#9989;): {subscriptionPassword}</div>
                    <div>Debit card to receive payment: {JSON.stringify(debitCardDetails)}</div>
                </div>
                <div>
                    <form onSubmit={moveToSubscriptionForm}>
                        <input className="sign_in_submit" type="submit" value="Actually, I want to make changes" />
                    </form>

                    <form onSubmit={addSubscription}>
                        <input className="sign_in_submit" type="submit" value="Go ahead and allow someone to share my subscription" />
                    </form>
                </div>
                {/* <Link to="/dashboard">Dashboard</Link> */}
            </div>
        </div>
    );
    
}
 
export default AddedSubscriptionReviewDetails;
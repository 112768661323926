import React, { useState, useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../../../state/store/store';
import classes from './published_subscriptions.module.css';

const JoinedSubscriptions = () => {

    const [ publishedSubscriptions, setPublishedSubscriptions ] = useState([])
    const [ joinedSubscriptions, setJoinedSubscriptions ] = useState([])
    // const [ profileEmail, setProfileEmail ] = useState("")

    useEffect(() => {        
        console.log(JSON.stringify(store.getState().islogged.profileEmail))
        const profileEmail = store.getState().islogged.profileEmail
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=subscriptions-get_published_subscriptions&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setPublishedSubscriptions(body.subscriptions) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=subscriptions-get_joined_subscriptions&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? setJoinedSubscriptions(body.subscriptions) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }, []);

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 

    let navigate = useNavigate();

    const redirect = (event) => {
        event.preventDefault();
        navigate("/dashboard/profiles/subscription_profile");
    }

    const moveToMessaging = () => {
        // event.preventDefault();
        const profileEmail = store.getState().islogged.profileEmail
        const userId = store.getState().islogged.userId
        const conversationId = "1"
        const data = {
            userId,
            conversationId
        }
        console.log("JOINSUB::");
        console.log(JSON.stringify(data));
        // navigate("/dashboard/subscriptions/chat", { state: data });
    }


    const confirmPaymentTransfer = () => {
        const token = store.getState().islogged.fan_token;
        const userId = store.getState().islogged.userId

        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=join_subscription-schedule_payment_transfer', {
            method: 'POST',
            body: JSON.stringify({ 
                userId
            }),
            headers: { 
                'content-type': 'application/json', 
                'authorization': `Bearer ${token.token}`
            }
        })
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? console.log("it worked "+JSON.stringify(body)) /*startParty(body.party_details)*/ : console.log("it didn't worked "+JSON.stringify(body)) /*continueCountdown(body.party_details)*/ ))
        .catch((error) => {
            console.log("Error message: "+error);
        });
    }
            
    
    return ( 
        <div className={classes.joined_subscriptions}>
            <div className={classes.subscriptions_item1}>
                <animated.div style={props}>
                    <h1>Joined Subscriptions</h1>
                    { joinedSubscriptions.length > 0 ?
                    <div>
                    {joinedSubscriptions.map((data, index) => (
                        <div className={classes.subscription} key={"subscription"+index}>
                            {data.streamingService}<br />
                            {data.subscriptionEmail}
                            {data.subscriptionOwnerEmail}<br />
                            <div onClick={moveToMessaging}>message</div>
                            <div onClick={confirmPaymentTransfer}>Confirm payment transfer</div>
                        </div>
                    ))}
                    </div>
                    :
                    <div className={classes.join_first_subscription_link}>
                        You haven't joined any subscriptions. Wanna join one? <Link to="/dashboard/join_subscription/subscriptions_search">Share here</Link>
                    </div>
                    }
                </animated.div> 
            </div>
        </div>
    );

}
 
export default JoinedSubscriptions;
const islogged = (state = false, action) => {
    switch (action.type) {
      case "SIGN_UP":
      case "SIGN_IN":
        localStorage.setItem("fan_token", action.fan_token);
        localStorage.setItem("user_id", action.id);
        localStorage.setItem("profileEmail", action.email);
        localStorage.setItem("authenticated", true);
        return {
          fan_token: action.fan_token,
          userId: action.id,
          profileEmail: action.email,
          authenticated: true
        };
      case 'SIGN_OUT':
        localStorage.removeItem("fan_token");
        localStorage.removeItem("user_id");
        localStorage.removeItem("profileEmail");
        localStorage.removeItem("authenticated");
        return {
          authenticated: false
        };
      default:
        return {
            authenticated: state
        };
    }
  }
  

  export default islogged;
import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useSpring, animated } from 'react-spring';
// import store from '../../../../../state/store/store';
// import VerifySubscription from './verify_subscription/verify_subscription'
import classes from './set_payment_method.module.css';


const AddSubscriptionSetPaymentMethod = () => {

    const { state } = useLocation();
    const subscriptionId = state.subscriptionId
    const profileEmail = state.profileEmail;
    const subscriptionEmail = state.subscriptionEmail;
    const subscriptionOwnerEmail = state.subscriptionOwnerEmail;
    const streamingService = state.streamingService;
    const [ cards, setCards ] = useState([])
    const [ paymentMethods, setPaymentMethods ] = useState([])
    const [ cardName, setCardName ] = useState("")
    const [ cardNumber, setCardNumber ] = useState("")
    const [ cardExp, setCardExp ] = useState("")
    const [ cardCVV, setCardCVV ] = useState("")
    const [ cardLast4, setCardLast4 ] = useState("")
    const [ isNewCard, setIsNewCard ] = useState(true)
    const cardDetails = {}

    let navigate = useNavigate();

    useEffect(() => {        
        fetch(process.env.REACT_APP_API_ENDPOINT+'resource=add_subscription-get_payment_methods&profileEmail='+profileEmail)
        .then(res => res.json())
        .then((body) => (body.response_msg === "success" ? checkIfPaymentmethodExist(body.paymentMethods) : console.log(body.error_msg)))
        .catch((error) => {
            console.log("Error message: "+error);
        });      

        console.log("STATE");
        console.log(JSON.stringify(state));
        
        console.log("STATE");
        console.log(JSON.stringify(state));
        if (paymentMethods.length > 0) {
            setCardDetailsFormatting()
        }else{
            setNewCardDetailsFormatting()
        }

    });


    const setCardDetailsFormatting = () => {
           
        //For card number formatted input
        var cardNum = document.getElementById('cardnumber');
        cardNum.onkeyup = function (e) {
            if (this.value == this.lastValue) return;

            var caretPosition = this.selectionStart;
            var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
            var parts = [];

            for (var i = 0, len = sanitizedValue.length; i < len; i +=4) { 
                parts.push(sanitizedValue.substring(i, i + 4)); 
            } 
            
            for (var i=caretPosition - 1; i>= 0; i--) {
                var c = this.value[i];
                if (c < '0' || c> '9') {
                    caretPosition--;
                }
            }

            caretPosition += Math.floor(caretPosition / 4);
            this.value = this.lastValue = parts.join(' ');
            this.selectionStart = this.selectionEnd = caretPosition;
        }

        //For Date formatted input
        var expDate = document.getElementById('expirationdate');
        expDate.onkeyup = function (e) {
            if (this.value == this.lastValue) return;

            var caretPosition = this.selectionStart;
            var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
            var parts = [];

            for (var i = 0, len = sanitizedValue.length; i < len; i +=2) { parts.push(sanitizedValue.substring(i, i + 2)); } for (var i=caretPosition - 1; i>= 0; i--) {
                var c = this.value[i];
                if (c < '0' || c> '9') {
                    caretPosition--;
                }
            }
            caretPosition += Math.floor(caretPosition / 2);
            this.value = this.lastValue = parts.join('/');
            this.selectionStart = this.selectionEnd = caretPosition;
        }
    }


    const setNewCardDetailsFormatting = () => {
           
        //For card number formatted input
        var cardNum = document.getElementById('newcardnumber');
        cardNum.onkeyup = function (e) {
            if (this.value == this.lastValue) return;

            var caretPosition = this.selectionStart;
            var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
            var parts = [];

            for (var i = 0, len = sanitizedValue.length; i < len; i +=4) { 
                parts.push(sanitizedValue.substring(i, i + 4)); 
            } 
            
            for (var i=caretPosition - 1; i>= 0; i--) {
                var c = this.value[i];
                if (c < '0' || c> '9') {
                    caretPosition--;
                }
            }

            caretPosition += Math.floor(caretPosition / 4);
            this.value = this.lastValue = parts.join(' ');
            this.selectionStart = this.selectionEnd = caretPosition;
        }

        //For Date formatted input
        var expDate = document.getElementById('newexpirationdate');
        expDate.onkeyup = function (e) {
            if (this.value == this.lastValue) return;

            var caretPosition = this.selectionStart;
            var sanitizedValue = this.value.replace(/[^0-9]/gi, '');
            var parts = [];

            for (var i = 0, len = sanitizedValue.length; i < len; i +=2) { parts.push(sanitizedValue.substring(i, i + 2)); } for (var i=caretPosition - 1; i>= 0; i--) {
                var c = this.value[i];
                if (c < '0' || c> '9') {
                    caretPosition--;
                }
            }
            caretPosition += Math.floor(caretPosition / 2);
            this.value = this.lastValue = parts.join('/');
            this.selectionStart = this.selectionEnd = caretPosition;
        }
    }


    const checkIfPaymentmethodExist = (storedPaymentMethods) => {
        if( storedPaymentMethods.length > 0 ){
            setPaymentMethods(storedPaymentMethods)
            console.log(JSON.stringify(storedPaymentMethods));
        }
    }


    const revealAddCard = () => {
        const x = document.getElementById("add_subscription_form-add_card");
        x.style.height === "0px" ? x.style.height = "100%" : x.style.height = "0px"
    }


    const showPassword = () => {
        const x = document.getElementById("add_subscription_form-subscription_password");
        x.type === "password" ? x.type = "text" : x.type = "password";
    }


    const moveToReviewSubscriptionDetails = ( event ) => {
        event.preventDefault()
        if (isNewCard) {
            cardDetails["cardName"] = cardName
            cardDetails["cardNumber"] = cardNumber
            cardDetails["cardExp"] = cardExp
            cardDetails["cardCVV"] = cardCVV
        }else{
            cardDetails["cardLast4"] = cardLast4
        }
        try {
            const joinSubscriptionDetails = {
                subscriptionId,
                streamingService, 
                subscriptionEmail,
                subscriptionOwnerEmail,
                profileEmail,
                cardDetails,
                isNewCard
            }
            console.log("TOTAL PAYLOAD:: "+JSON.stringify(joinSubscriptionDetails))
            navigate("/dashboard/join_subscription/join_subscription_review_details", { state: joinSubscriptionDetails });
        }catch(error){
            console.log(error);
        }
    }


    const toggleNewCardDetails = (event) => {
        const x = document.getElementById("join_subscription_add_new_payment_method");
        if(event.target.value === "newSubscriptionPayment"){
            x.style.height = "100%";
            x.style.overflow = "visible";
        }else{
            x.style.height = "0px";
            x.style.overflow = "hidden";
        }
    }


    const displaySuccessMessage = (message) => {
        console.log("success message: "+message)
    }


    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 

    
    return ( 
        <div className={classes.add_subscription_form_container}>
            <div className={classes.add_subscription_form} id="add_subscription_form">
                <animated.div style={props}>
                    <h1>Set Payment Method</h1>
                    Choose the debit card that we should debit your share of the subscription cost.
                    <Popup
                        trigger={<Link>Why?</Link>}
                        position={['bottom left', 'bottom right']}
                        closeOnDocumentClick
                        // keepTooltipInside="#add_subscription_form"
                        keepTooltipInside={true}
                        contentStyle={{ width: '20%' }}
                    >
                    <div className={classes.add_subscription_form_info}> 
                        We do not want you to share your personal email and password 
                        with anyone even if you have different passwords for your email 
                        and your current subscription service. 
                        When you're done, return to this page and check the confirmation box.
                    </div>
                    </Popup>                       
                        Card to receive subscription payment:<br />
                        { paymentMethods.length > 0 ?
                        <div>
                            <form onSubmit={moveToReviewSubscriptionDetails}> 
                                <div onChange={toggleNewCardDetails}>
                                    {paymentMethods.map((data, index) => (
                                        <div key={"cardlast4_"+index}>
                                            <input type="radio" id="subscriptionPayment" name="subscriptionPaymentRadio" value={data.cardLast4} onClick={() => (
                                                setCardLast4(data.cardLast4),
                                                setIsNewCard(false)
                                            )} />
                                            <label for="subscriptionPayment">{data.cardLast4}</label><br />
                                        </div>
                                    ))}
                                    <input className="sign_in_submit" type="submit" value="Use this card, Review subscription details >" />
                            
                                    <div>
                                        <input type="radio" id="subscriptionPayment" name="subscriptionPaymentRadio" value="newSubscriptionPayment" />
                                        <label for="subscriptionPayment">Add a new payment method</label><br />
                                    </div>
                                </div>
                            </form>

                            <form onSubmit={moveToReviewSubscriptionDetails}>  
                                <div className={classes.join_subscription_add_new_payment_method} id="join_subscription_add_new_payment_method">
                                    <div class="form-container">
                                        <div class="field-container">
                                            <label for="name">Name</label>
                                            <input id="name" maxlength="20" type="text" onChange={(e) => setCardName(e.target.value)} required />
                                        </div>
                                        <div class="field-container">
                                            <label for="cardnumber">Card Number</label>
                                            <input id="cardnumber" type="text" pattern="[0-9\s]*" inputmode="numeric" size="18" minlength="19" maxlength="19"  onChange={(e) => setCardNumber(e.target.value)} required />
                                        </div>
                                        <div class="field-container">
                                            <label for="expirationdate">Expiration (mm/yy)</label>
                                            <input id="expirationdate" type="text" pattern="[0-9\/]*" inputmode="numeric" placeholder="MM/YY" size="6" minlength="5" maxlength="5" onChange={(e) => setCardExp(e.target.value)} required />
                                        </div>
                                        <div class="field-container">
                                            <label for="securitycode">Security Code</label>
                                            <input id="securitycode" type="text" pattern="[0-9]*" inputmode="numeric" size="1" minlength="3" maxlength="3" onChange={(e) => setCardCVV(e.target.value)} required />
                                        </div>
                                    </div>   
                                    <input className="sign_in_submit" type="submit" value="Review details >" />                           
                                </div> 
                            </form>
                        </div>
                        :
                        <form onSubmit={moveToReviewSubscriptionDetails}>  
                            <div class="form-container">
                                <div class="field-container">
                                    <label for="name">Name</label>
                                    <input id="name" maxlength="20" type="text" onChange={(e) => setCardName(e.target.value)} required />
                                </div>
                                <div class="field-container">
                                    <label for="newcardnumber">Card Number</label>
                                    <input id="newcardnumber" type="text" pattern="[0-9\s]*" inputmode="numeric" size="18" minlength="19" maxlength="19"  onChange={(e) => setCardNumber(e.target.value)} required />
                                </div>
                                <div class="field-container">
                                    <label for="newexpirationdate">Expiration (mm/yy)</label>
                                    <input id="newexpirationdate" type="text" pattern="[0-9\/]*" inputmode="numeric" placeholder="MM/YY" size="6" minlength="5" maxlength="5" onChange={(e) => setCardExp(e.target.value)} required />
                                </div>
                                <div class="field-container">
                                    <label for="securitycode">Security Code</label>
                                    <input id="securitycode" type="text" pattern="[0-9]*" inputmode="numeric" size="1" minlength="3" maxlength="3" onChange={(e) => setCardCVV(e.target.value)} required />
                                </div>
                            </div>        
                            <input className="sign_in_submit" type="submit" value="Review details >" />               
                        </form>
                        }

                </animated.div>               
            </div>
        </div>
    );

}
 
export default AddSubscriptionSetPaymentMethod;
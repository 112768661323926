import React from 'react';
import { useSpring, animated } from 'react-spring';
import AddSubscription from './add_subscription/add_subscription';
import Charge from './charge/charge';
import Chat from './chat/chat';
import JoinSubscription from './join_subscription/join_subscription';
import Password from './password/password';
import PaymentMethod from './payment_method/payment_method';
import Payout from './payout/payout';
import Reviews from './reviews/reviews';
import Schedule from './schedule/schedule';
import Strikes from './strikes/strikes';
import PublishedSubscriptions from './subscriptions/published_subscriptions';
import JoinedSubscriptions from './subscriptions/joined_subscriptions';
import store from '../../../state/store/store';
import classes from './dashboard.module.css';


const Dashboard = () => {

    const props = useSpring({ 
        to: { opacity: 1 }, 
        from: { opacity: 0 }, 
        delay: 1000
    }); 

    return ( 
        <animated.div style={props}>
        <div className={classes.dashboard_container}>
            <div className={classes.dashboard}>  
                <div className={classes.add_subscription}>
                    <AddSubscription />
                </div>
                <div className={classes.join_subscription}>
                    <JoinSubscription />
                </div>
                <div className={classes.published_subscriptions}>
                    <PublishedSubscriptions />
                </div>
                <div className={classes.joined_subscriptions}>
                    <JoinedSubscriptions />
                </div>
                 {/* <div className={classes.charge}>
                    <Charge />
                </div> */}
                {/* <div className={classes.chat}>
                    <Chat />
                </div>  */}
                {/* <div className={classes.password}>
                    <Password />
                </div> */}
                {/* <div className={classes.payment_method}>
                    <PaymentMethod />
                </div> */}
                {/* <div className={classes.payout}>
                    <Payout />
                </div> */}
                {/* <div className={classes.reviews}>
                    <Reviews />
                </div> */}
                {/* <div className={classes.schedule}>
                    <Schedule />
                </div> */}
                {/* <div className={classes.strikes}>
                    <Strikes />
                </div> */}
            </div>
        </div>
        </animated.div>   
    );

}
 
export default Dashboard;
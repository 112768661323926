import React, { useState, useEffect }  from 'react';
import { useSpring, animated } from 'react-spring';
import { Link, useNavigate, useParams } from 'react-router-dom';
import classes from './reset_password.module.css';

const ResetPassword = () => {

    const props = useSpring({ 
                    to: { opacity: 1 }, 
                    from: { opacity: 0 }, 
                    delay: 1000
                }); 
    
                

    const params = useParams();
    const email = params.email;
    const token = params.token;

    const [newPassword, setNewPassword] = useState("")
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
    const [resetPasswordMsg, setResetPasswordMsg] = useState("");
    const [resetPasswordErrorMsg, setResetPasswordErrorMsg] = useState("");
    const [tokenIsValid, setTokenIsValid] = useState(false);
    
    let navigate = useNavigate();
 
                
    useEffect(() => {
        const isTokenValid = () => {
            fetch(process.env.REACT_APP_API_ENDPOINT+`/api/signin/forgot_password/verify_reset_password_token/${email}/${token}`)
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? setTokenIsValid(true) : setTokenIsValid(false) ))
            .catch((error) => {
                console.log("Error message: "+error);
            });
        }

        (async () => {
            isTokenValid();
        })();
        
        
        return () => {
            setResetPasswordMsg("Nice, you're password was successfully updated. Let's sign you in.")
            //delete old token
            setTimeout(() => {
                //
            }, 4000);
        }
    }, []);


    const resetPassword = (event) => {
        event.preventDefault();
        if (newPassword !== newPasswordConfirmation) {
            resetPasswordMsg("Make sure the pssword and password confirmation are the same.");
        }else{
            fetch(process.env.REACT_APP_API_ENDPOINT+'/api/signin/update_password', {
                method: 'POST',
                body: JSON.stringify({ 
                    email,
                    new_password: newPassword
                }),
                headers: { 
                    'Content-Type': 'application/json'
                }
            })
            .then(res => res.json())
            .then((body) => (body.response_msg === "success" ? navigate("/auth/sign_in", { state: email }) : setResetPasswordErrorMsg(body.error_msg)))
            .catch((error) => {
                console.log("Error message: "+error);
            });
        }
    }


    return ( 
        <div className={classes.reset_password_container}>
            <div className={classes.reset_password}>
                { tokenIsValid ?
                    <animated.div style={props}>
                        <h1>Set a new password</h1>
                        <div className={classes.reset_password_error_message}>{resetPasswordErrorMsg}</div>
                        <div className={classes.reset_password_message}>{resetPasswordMsg}</div>
                        <div>Enter your email here. Click on "Send Code" and check your email for the verification code.</div>
                        <form onSubmit={resetPassword}>
                            <input type="password" onChange={e => setNewPassword(e.target.value)} id="newPassword" name="password" placeholder="New password" /><br />
                            <input type="password" onChange={e => setNewPasswordConfirmation(e.target.value)} id="newPasswordConfirmation" name="password" placeholder="Confirm new password" /><br />
                            {/* <input type="checkbox" className="sign_in_checkbox" value="Remember Me!" /> Remember Me!<br /> */}
                            <input className="send_code_submit" type="submit" value="Reset Password" />
                        </form>

                        <div className={classes.reset_password_alternatives}>
                            <div className={classes.sign_in_forgot_password}>
                                <Link to="/auth/sign_in">Sign in</Link>
                            </div>

                            <div className={classes.reset_password_link}>
                                <Link to="/auth/sign_up">Create an Account</Link>
                            </div>
                        </div>

                        <div className={classes.back_to_dashboard_link}>
                            <Link to="/">{"< Back"}</Link>
                        </div>
                    </animated.div>   
                :
                    <div>
                        Sorry, your link may have expired or you have an invalid token.<br /><br /><br /><br />
                        <div className={classes.back_to_dashboard_link}>
                            <Link to="/auth/sign_in/forgot_password">{'\u21BA'}{" Try again"}</Link>
                        </div>
                    </div>
                    
                }
            </div>
        </div>
    );

}
 
export default ResetPassword;